
const cp_fields = [
    {
        inputField: "insectiside_totalsale",
        outputField: "insectiside_retail"
    },
    {
        inputField: "fungiside_totalsale",
        outputField: "fungiside_retail"
    },
    {
        inputField: "herbiside_totalsale",
        outputField: "herbiside_retail"
    },
    {
        inputField: "seedtreatment_totalsale",
        outputField: "seedtreatment_retail"
    },
    {
        inputField: "fromdistributor_totalsale",
        outputField: "fromdistributor_retail"
    },
    {
        inputField: "directfromcompany_totalsale",
        outputField: "directfromcompany_retail"
    },
    {
        inputField: "online_totalsale",
        outputField: "online_retail"
    },
    {
        inputField: "govtbodies_totalsale",
        outputField: "govtbodies_retail"
    },
    {
        inputField: "others_totalsale",
        outputField: "others_retail"
    },
];

const calculateTop5Companies = (updatedFormData) => {

    let othercompanysales =
        parseInt(
          updatedFormData["company_top_nametop1total"]
            ? updatedFormData["company_top_nametop1total"]
            : 0
        ) +
        parseInt(
          updatedFormData["company_top_nametop2total"]
            ? updatedFormData["company_top_nametop2total"]
            : 0
        ) +
        parseInt(
          updatedFormData["company_top_nametop3total"]
            ? updatedFormData["company_top_nametop3total"]
            : 0
        ) +
        parseInt(
          updatedFormData["company_top_nametop4total"]
            ? updatedFormData["company_top_nametop4total"]
            : 0
        ) +
        parseInt(
          updatedFormData["company_top_nametop5total"]
            ? updatedFormData["company_top_nametop5total"]
            : 0
        ) +
        parseInt(
          updatedFormData["bayertotal2024"]
            ? updatedFormData["bayertotal2024"]
            : 0
        );

    return othercompanysales;

}



export const updateCPRelatedFields = async (formdata, updatedValue) => {
    let formCopy = formdata;
    let updatedKeys = {};
    cp_fields.forEach(async (item)=>{
        if(item.inputField in formCopy){
             updatedKeys[item.outputField] = await ( (formCopy[item.inputField]/100) * updatedValue  ).toFixed(2);
        }
    });


    let othercompanies = calculateTop5Companies(formdata);
    updatedKeys["othercompaniesaggregatedtotal"] =
        formdata["CP_totalsale_section1_2024"] - othercompanies;

        updatedKeys["totalcompaniesaggregated"] = othercompanies;

    return updatedKeys;
}