import { colors, Font } from "../styles/standards";



export const DropSelector = ({SelectedData, placeHolder, onPress}) => {
  return (
    <div style={style.container} onClick={onPress}>
      <span style={style.selected}>{SelectedData??placeHolder}</span>
      <i className="fa fa-caret-down" style={style.downicon}></i>
    </div>
  );
};
const style = {
    container:{
        border: '1px solid #767676',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        height:50,
        width:'100%',
        paddingLeft:20,
        paddingRight:20,
        marginTop:10,
        boxSizing: 'border-box',
        borderRadius: 5,
    },
    selected:{
        ...Font.Robotoregular,
        fontWeight:300,
        display: "block",
        color: colors.Black,
        fontSize: 13,
    },
    downicon:{
        fontSize:22,
    }
}