export const Constants = {
    retailer_survey: 'Retailer Survey',
    new_survey: 'New Survey',
    sub_dist: 'Sub District',
    search_sub: "Search Sub - District",
    search_by_name_number: "Search By Name OR Number",
    otp_verification:'OTP Verification',
    send_sms: `We will send an SMS to verify participant's mobile number`,
    otp_proceed: "Please proceed with OTP verification to start the survey",
    concent: 'I confirm that i have concent of the participant for OTP verification and survey.',
    request_otp:'Request OTP',
    enter_otp: 'Please enter 4 digit OTP sent via SMS on ',
    enter_otp_here: 'Enter OTP here.',
    submit: 'Submit',
    didnt_otp:`Didn't get the OTP?`,
    resend_OTP: 'Resend OTP' ,
    step1: "Step 1 of 4 - Personal Details",
    step2: "Step 2 of 4 - RWDS",
    step3: "Step 3 of 4 - Market",
    step4: "Shop Photo and Location - Final Step",
    fot: "FOT",
    enter:'Enter 10 Digit Phone Number',
    tenDigits: "Please enter 10 digits phone number"
}
export const subDist = {
    heading: 'Search Sub - Districts',
    placeholder: 'Search Sub - Districts'
}

export const FOT = {
    heading: 'Search FOT',
    placeholder: 'Search FOT'
}

export const Step1QuestionIdConstants = {
    QUESTION_1 : "name",
    QUESTION_2 : "mobile_number",
    QUESTION_3 : "email",
    QUESTION_4 : "age",
    QUESTION_5 : "shop_name",
    QUESTION_6 : "establishment_type",
    QUESTION_7 : "is_businesspartner",
    QUESTION_8 : "customer_type",
    QUESTION_9 : "state",
    QUESTION_10 : "district",
    QUESTION_11 : "subdistrict",
    QUESTION_12 : "village_or_urban_body",
    QUESTION_13 : "village",
    QUESTION_14 : "urban_body",
    QUESTION_15 : "territory",
    QUESTION_16 : "fot",
    QUESTION_17 : "pin_code",
    QUESTION_18 : "gst",
    QUESTION_19 : "duration_in_pesticides_seeds_business",
    QUESTION_20 : "is_farmer",
    QUESTION_21 : "is_agrigraduate",
    QUESTION_22 : "license_pesticides",
    QUESTION_23 : "license_seeds",
    QUESTION_24 : "license_fertilizers"
}

export const Step2QuestionIdConstants = {
    CATEGORY_WISE_ANNUAL_SALES_QID : 1,
    CATEGORY_WISE_CP_SALES_2024_QID : 2,
    CP_PRODUCTS_FROM_EACH_SOURCE_2024_QID : 3,
    TOP_5_COMPANIES_CP_2024_QID : 4,
    TOP_3_CROPS_CP_2024_QID : 5,
    PERCENTAGE_CP_WHOLESALE_IN_CASH_QID : 6,
    PERCENTAGE_CP_RETAIL_IN_CASH_QID : 7,
    TOTAL_NO_OF_RETAILERS_SERVE_QID : 8,
    CATEGORY_WISE_SEED_SALES_2024_QID : 9,
    TOP_3_RICE_HYBRID_SEED_2024_QID : 10,
    TOP_3_CORN_HYBRID_SEED_2024_QID : 11,
    IS_GST_INCLUDED : 12
}

export const Step3QuestionIdConstants = {
    QUESTION_1 : "rank_farmer_purchase_decision",
    QUESTION_2 : "rank_retailer_purchase_decision",
    QUESTION_3 : "bayer_sales_effective_activities"
}

export const Step4QuestionIdConstants = {
    QUESTION_1 : "shop_photo",
    QUESTION_2 : "shop_location",
}