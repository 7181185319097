import { useState } from "react";
import { Concent } from "../components/concent";
import { Header } from "../components/headerTypeOne";
import { StickyActionContainer } from "../components/stickyByttonWithConcent";
import { TextBoxWithIcon } from "../components/textBoxWithIcon";
import { Constants } from "../constants/textconstants";
import { Style } from "../styles/otpverification";
import { useLocation } from "react-router-dom";

export const OTP = () => {
  const data = useLocation();

  const phoneNumber =
    data.state && data.state.retailer && data.state.retailer.mobile_number;
  const shop_name =
    data.state && data.state.retailer && data.state.retailer.shop_name;

  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const isNew = data.state && data.state.newuser;
  return (
    <div>
      <Header
        header={Constants.retailer_survey}
        subheader={Constants.otp_verification}
      />
      <div style={Style.container}>
        <span style={Style.infoText}>{Constants.send_sms}</span>
        <span style={Style.shopname}>{shop_name}</span>
        <TextBoxWithIcon
          iconname={"fa fa-phone"}
          handler={(e) => {
            const inputValue = e.replace(/\D/g, "");
            if (inputValue.length <= 10) {
              setNewPhoneNumber(inputValue);
              setError("");
            } else {
              setError(Constants.tenDigits);
            }
          }}
          placeHolder={newPhoneNumber === "" ? Constants.enter : ""}
          value={newPhoneNumber === "" ? phoneNumber : newPhoneNumber}
          isDisabled={false}
        />
        {error.length > 0 && <span style={Style.errormessage}>{error}</span>}
        <span style={Style.infoTexrtSub}>{Constants.otp_proceed}</span>
      </div>
      <StickyActionContainer>
        <Concent
          phoneNumber={newPhoneNumber === "" ? phoneNumber : newPhoneNumber}
          isNewUser={true}
          retailer={data.state ? data.state.retailer : {}}
          oldPhone={phoneNumber}
          creatingUser={isNew}
          userID = {data.state.parsedParameters? data.state.parsedParameters.user_id: ''}
          territoryID = {data.state.parsedParameters? data.state.parsedParameters.territory_id: ''}
        />
      </StickyActionContainer>
    </div>
  );
};
