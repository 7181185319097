export const safeParseInt = (value) => {
    const parsed = parseInt(value);
    return isNaN(parsed) ? 0 : parsed;
  };
  
  // Utility function for percentage calculation
  export const calculatePercentage = (numerator, denominator) => {
    if (!denominator || denominator === 0) return 0;
    return ((numerator / denominator) * 100).toFixed(2);
  };
  
  // Utility function for unit conversion
  export const convertValueByUnit = (value, unit) => {
      if (value === 0) return 0;
      switch (unit) {
        case "lakhs": return value / 100000;
        case "crores": return value / 10000000;
        case "mt": return value / 1000;
        default: return value;
      }
    };
  
  // Function to calculate sums based on field key
  export  const calculateFieldSum = (formData, fieldKey, resultKey) => {
    const sum = Object.keys(formData).reduce(
      (acc, key) => (key.includes(fieldKey) ? acc + safeParseInt(formData[key]) : acc),
      0
    );
    return { ...formData, [resultKey]: sum };
  };
  
  // Function to calculate aggregated totals
  export const calculateAggregatedTotals = (formData) => {
    const otherCompanySales =
      safeParseInt(formData["company_top_nametop1total"]) +
      safeParseInt(formData["company_top_nametop2total"]) +
      safeParseInt(formData["company_top_nametop3total"]) +
      safeParseInt(formData["company_top_nametop4total"]) +
      safeParseInt(formData["company_top_nametop5total"]) +
      safeParseInt(formData["bayertotal2024"]);
  
    return {
      ...formData,
      othercompaniesaggregatedtotal: formData["CP_totalsale_section1_2024"] - otherCompanySales,
      totalcompaniesaggregated: otherCompanySales,
    };
  };
  
  export  const calculateAverageNDP = (formData, riceKey, volumeKey, averageNDPKey, totalRetailRiceKey, seePaddyHybridRetailKey) => {
      const sum = formData[riceKey] / formData[volumeKey];
      const averageNDP = sum.toFixed(2);
  
      let OtherSum = safeParseInt(formData[seePaddyHybridRetailKey]) -
      (safeParseInt(formData["retailsalesrice_RICE1_totalsale"]) +
        safeParseInt(formData["retailsalesrice_RICE2_totalsale"]) +
        safeParseInt(formData["retailsalesrice_RICE3_totalsale"]) +
        safeParseInt(formData["bayerricetotal2024_BayerRICE_totalsale"]));
  
      return {
          ...formData,
          [averageNDPKey]: averageNDP,
          [totalRetailRiceKey]: OtherSum
      }
  }
  export const calculateTotalRetailRiceVolume = (formData) => {
    console.log(safeParseInt(formData["retailvolumerice_RICE3"]) ,
    safeParseInt(formData["retailvolumerice_RICE2"]) ,
    safeParseInt(formData["retailvolumerice_RICE1"]) ,
    safeParseInt(formData["bayerricewretailvolume_BayerRICE"]),'==--')
      return {
          ...formData,
          totalRetailRiceVolume : safeParseInt(formData["retailvolumerice_RICE3"]) +
          safeParseInt(formData["retailvolumerice_RICE2"]) +
          safeParseInt(formData["retailvolumerice_RICE1"]) +
          safeParseInt(formData["bayerricewretailvolume_BayerRICE"])
      }
  }
  