import { useEffect, useRef, useState } from "react";
import { Header } from "../components/headerTypeOne";
import { config1 } from "../config/step1";
import {
  Constants,
  Step1QuestionIdConstants,
} from "../constants/textconstants";
import { Style } from "../styles/step1";
import { SaveAndCont } from "../components/saveandcont";
import { useLocation, useNavigate } from "react-router-dom";

import {
  RadioControl,
  SingleSelect,
  TextDate,
} from "../helper-functions/utils";
import { setLoader, showToast } from "../store/slices/util";
import { useDispatch, useSelector } from "react-redux";
import { ProceedNotification } from "../components/proceedNotify";
import {
  getDistrict,
  getSubDistrict,
  getTerritoryAndFOT,
  getTerritoryAndFOTForUrbanBody,
  getUrbanBodies,
  getVillages,
} from "../helper-functions";
import { get, post } from "../config/api";
import { HasLiscence } from "../components/hasLiscence";
import { SaveProgress } from "../components/saveProgress";
import { LoaderFaded } from "../components/loaderfaded";
import { InfoPopUp } from "../components/infoPopuop";
import { API_URL } from "../config/sys";

export const Step1 = () => {
  const [formData, setFormData] = useState({});
  const data = useLocation();
  const [fieldErrors, setFieldErrors] = useState({});
  const [enabledFields, setEnabledFields] = useState({
    district: !data.state.isNewUser,
    subdistrict: !data.state.isNewUser,
    radioSelection: !data.state.isNewUser,
    village: false,
    urban: false,
    territory: !data.state.isNewUser,
    fot: !data.state.isNewUser,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reviewInfo, setReviewInfo] = useState(false);
  const [config, setConfig] = useState(config1);
  const [showSorryPup, setshowSorryPup] = useState(false);
  const [saveProgress, setSaveProgress] = useState(false);
  const [localUrban, setUrbans] = useState(null);
  const Utils = useSelector((state) => state.Utils);
  const [showError, setShowError] = useState(false);
  const [createNewUser, setCreateNewUser] = useState(false);

  useEffect(() => {
    dispatch(setLoader(true));
    const fetchData = async () => {
      dispatch(setLoader(true));
      dispatch(
        showToast({ toast: true, toastmessage: "OTP verified successfully!" })
      );
      setTimeout(() => {
        dispatch(showToast({ toast: false, toastmessage: "" }));
      }, 1500);

      const newstate = await localStorage.getItem("createnewuser");

      if (newstate === "false") {
        try {
          const mobilenumber = await localStorage.getItem("mobile_number");
          const customer_id_stored = await localStorage.getItem("customer_id");
          const customer_id = data.state.retailer
            ? data.state.retailer.customer_id
            : customer_id_stored;
          const response = await get(
            `/survey/responses?customer_id=${customer_id}&page_number=page1&user_id=${data.state.userID}&mobile_number=${mobilenumber}`
          ).catch((e) => {
            dispatch(setLoader(false));
          });
          let tempFormData = {};
          config.map((fields) => {
            response.responses.map((responsefield) => {
              if (fields.identifier == responsefield.question_id) {
                tempFormData[fields.identifier] = responsefield.response;
              }
            });
          });

          setFormData(tempFormData);
        } catch (error) {
          dispatch(setLoader(false));
          console.log(error);
        }
      } else {
        setCreateNewUser(true);
      }
      dispatch(setLoader(false));

      setFormData((prev) => ({
        ...prev,
        [Step1QuestionIdConstants.QUESTION_2]: data.state.retailer
          ? data.state.retailer.mobile_number
          : data.state.mobile_number,
      }));
    };

    fetchData();
  }, [data.state]);

  useEffect(() => {
    if (data.state.retailer) {
      setFormData((prev) => ({
        ...prev,
        [Step1QuestionIdConstants.QUESTION_5]: data.state.retailer.shop_name,
        [Step1QuestionIdConstants.QUESTION_2]:
          data.state.retailer.mobile_number,
      }));
    }
  }, [data.state]);

  const handleChange = async (identifier, value) => {
    console.log(identifier, value);
    if (typeof value === "object") {
      if (identifier == Step1QuestionIdConstants.QUESTION_9) {
        setFormData((prev) => ({
          ...prev,
          [Step1QuestionIdConstants.QUESTION_9]: value.id,
        }));
        dispatch(setLoader(true));
        getDistrict(value.id).then((districts) => {
          const updatedConfig = config.map((item) => {
            if (item.identifier === Step1QuestionIdConstants.QUESTION_10) {
              return { ...item, options: districts };
            }
            return item;
          });
          dispatch(setLoader(false));
          setConfig(updatedConfig);
        });
      } else if (identifier === Step1QuestionIdConstants.QUESTION_10) {
        setFormData((prev) => ({ ...prev, ["district_id"]: value.id }));
        dispatch(setLoader(true));
        getSubDistrict(value.id).then((subdistricts) => {
          const updatedConfig = config.map((item) => {
            if (item.identifier === Step1QuestionIdConstants.QUESTION_11) {
              return { ...item, options: subdistricts };
            }
            return item;
          });
          dispatch(setLoader(false));
          setConfig(updatedConfig);
        });
      } else if (identifier === Step1QuestionIdConstants.QUESTION_11) {
        handleChange(identifier, "Village");
        setFormData((prev) => ({ ...prev, ["sub_district_id"]: value.id }));
        dispatch(setLoader(true));
        await getUrbanBodies(value.id).then((urbans) => {
          setUrbans(urbans);
          const updatedConfig = config.map((item) => {
            if (item.identifier === Step1QuestionIdConstants.QUESTION_14) {
              return { ...item, options: urbans };
            }
            return item;
          });

          setConfig(updatedConfig);
        });
        await getVillages(value.id).then((villages) => {
          const updatedConfig = config.map((item) => {
            if (item.identifier === Step1QuestionIdConstants.QUESTION_13) {
              return { ...item, options: villages };
            }
            return item;
          });
          setConfig(updatedConfig);
        });
        dispatch(setLoader(false));
      } else if (identifier === Step1QuestionIdConstants.QUESTION_13) {
        setFormData((prev) => ({ ...prev, ["village_id"]: value.id }));
        dispatch(setLoader(true));
        await getTerritoryAndFOT(value).then((data) => {
          const updatedConfig1 = config.map((item) => {
            if (item.identifier === Step1QuestionIdConstants.QUESTION_15) {
              setFormData((prev) => ({
                ...prev,
                [Step1QuestionIdConstants.QUESTION_15]:
                  data[Step1QuestionIdConstants.QUESTION_15],
              }));
              return { ...item, autopopulateColumn: true };
            }
            if (item.identifier === Step1QuestionIdConstants.QUESTION_16) {
              setFormData((prev) => ({
                ...prev,
                [Step1QuestionIdConstants.QUESTION_16]:
                  data[Step1QuestionIdConstants.QUESTION_16],
              }));
              return { ...item, autopopulateColumn: true };
            }
            return item;
          });

          setConfig(updatedConfig1);
        });
        dispatch(setLoader(false));
      } else if (identifier === Step1QuestionIdConstants.QUESTION_14) {
        let territory = data.state.territoryID;
        dispatch(setLoader(true));
        await getTerritoryAndFOTForUrbanBody(territory).then(async (data) => {
          const updatedConfig = await config.map((item) => {
            if (item.identifier === Step1QuestionIdConstants.QUESTION_15) {
              setFormData((prev) => ({
                ...prev,
                [Step1QuestionIdConstants.QUESTION_15]: data["territory_name"],
              }));
              return { ...item, autopopulateColumn: true };
            }
            if (item.identifier === Step1QuestionIdConstants.QUESTION_16) {
              return {
                ...item,
                options: data.fots,
                autopopulateColumn: false,
              };
            }
            return item;
          });

          setConfig(updatedConfig);
        });
        dispatch(setLoader(false));
      }
      setFormData((prev) => ({ ...prev, [identifier]: value.value }));
    } else {
      if (
        value === "Village" &&
        identifier == Step1QuestionIdConstants.QUESTION_12
      ) {
        setFormData((prev) => ({
          ...prev,
          [Step1QuestionIdConstants.QUESTION_13]: null,
          [Step1QuestionIdConstants.QUESTION_15]: null,
          [Step1QuestionIdConstants.QUESTION_16]: null,
        }));
      }
      if (
        value === "Urban Body" &&
        identifier == Step1QuestionIdConstants.QUESTION_12
      ) {
        setFormData((prev) => ({
          ...prev,
          [Step1QuestionIdConstants.QUESTION_14]: null,
          [Step1QuestionIdConstants.QUESTION_15]: null,
          [Step1QuestionIdConstants.QUESTION_16]: null,
        }));
      }

      if (identifier === Step1QuestionIdConstants.QUESTION_17) {
        if (value.length <= 6) {
          setFormData((prev) => ({ ...prev, [identifier]: value }));
        }
      } else if (identifier === Step1QuestionIdConstants.QUESTION_18) {
        if (value.length <= 15) {
          setFormData((prev) => ({
            ...prev,
            [identifier]: value.replace(/[^a-zA-Z0-9 ]/g, "").toUpperCase(),
          }));
        }
      } else {
        setFormData((prev) => ({ ...prev, [identifier]: value }));
      }
    }

    if (data.state.isNewUser) {
      // Enable dependent fields
      if (identifier === Step1QuestionIdConstants.QUESTION_9) {
        setEnabledFields((prev) => ({
          ...prev,
          district: true,
          subdistrict: false,
          radioSelection: false,
          village: false,
          urban: false,
          territory: false,
          fot: false,
        }));
        setFormData((prev) => ({
          ...prev,
          [Step1QuestionIdConstants.QUESTION_10]: null,
          [Step1QuestionIdConstants.QUESTION_11]: null,
          [Step1QuestionIdConstants.QUESTION_13]: null,
          [Step1QuestionIdConstants.QUESTION_14]: null,
          [Step1QuestionIdConstants.QUESTION_15]: null,
          [Step1QuestionIdConstants.QUESTION_16]: null,
        }));
      } else if (identifier === Step1QuestionIdConstants.QUESTION_10) {
        setEnabledFields((prev) => ({
          ...prev,
          subdistrict: true,
          radioSelection: false,
          village: false,
          urban: false,
          territory: false,
          fot: false,
        }));
        setFormData((prev) => ({
          ...prev,
          [Step1QuestionIdConstants.QUESTION_11]: null,
          [Step1QuestionIdConstants.QUESTION_13]: null,
          [Step1QuestionIdConstants.QUESTION_14]: null,
          [Step1QuestionIdConstants.QUESTION_15]: null,
          [Step1QuestionIdConstants.QUESTION_16]: null,
        }));
      } else if (identifier === Step1QuestionIdConstants.QUESTION_11) {
        setEnabledFields((prev) => ({
          ...prev,
          radioSelection: true,
          village: false,
          urban: false,
          territory: false,
          fot: false,
        }));
        setFormData((prev) => ({
          ...prev,
          [Step1QuestionIdConstants.QUESTION_13]: null,
          [Step1QuestionIdConstants.QUESTION_14]: null,
          [Step1QuestionIdConstants.QUESTION_15]: null,
          [Step1QuestionIdConstants.QUESTION_16]: null,
        }));
        handleChange(Step1QuestionIdConstants.QUESTION_12, "Village");
      } else if (identifier === Step1QuestionIdConstants.QUESTION_7) {
        setFormData((prev) => ({
          ...prev,
          [Step1QuestionIdConstants.QUESTION_8]: null,
        }));
      } else if (identifier === Step1QuestionIdConstants.QUESTION_12) {
        setEnabledFields((prev) => ({
          ...prev,
          village: value === "Village",
          urban: value === "Urban Body",
          territory: false,
          fot: false,
        }));
        if (value === "Urban Body") {
          const updatedConfig = config.map((item) => {
            if (item.identifier === Step1QuestionIdConstants.QUESTION_14) {
              return { ...item, options: localUrban };
            }
            return item;
          });
          setConfig(updatedConfig);
        }
      } else if (
        identifier === Step1QuestionIdConstants.QUESTION_13 ||
        identifier === Step1QuestionIdConstants.QUESTION_14
      ) {
        setEnabledFields((prev) => ({
          ...prev,
          territory: true,
          fot: true,
        }));
      }
    }
    dispatch(setLoader(false));
  };

  // Validate required fields
  const validateFields = () => {
    const errors = {};
    config.forEach((field) => {
      const { identifier, isOptional } = field;

      if (!isOptional && !formData[identifier]) {
        errors[identifier] = true;
      }
      if (
        identifier === Step1QuestionIdConstants.QUESTION_22 ||
        identifier === Step1QuestionIdConstants.QUESTION_23 ||
        identifier === Step1QuestionIdConstants.QUESTION_24
      ) {
        if (formData[identifier] === "Village") {
          errors[identifier] = true;
        }
      }
    });
    setFieldErrors(errors);
    if (Object.keys(errors).length !== 0) {
      setShowError(true);
    }
    return Object.keys(errors).length === 0;
  };
  const close = () => {
    setReviewInfo(false);
  };
  const createPostBody = (selectedAnswers) => {
    let updatedPostBody = [];
    config.forEach((item) => {
      updatedPostBody.push({
        question_id: item.identifier,
        response: formData[item.identifier],
      });
    });
    return updatedPostBody;
  };
  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  const saveProgresses = async () => {
    let territoryID = await localStorage.getItem("territory_id");
    let userID = await localStorage.getItem("user_id");

    dispatch(setLoader(true));
    let PostBody = createPostBody(formData);

    const structured = {
      user_id: data.state.userID
        ? data.state.userID
        : data.state.parsedParameters
        ? data.state.parsedParameters.user_id
        : "",
      page_number: "page1",
      responses: PostBody,
      mobile_number: formData[Step1QuestionIdConstants.QUESTION_2],
      action: "save",
    };
    const createUserdata = getUserdata(formData);
    const customer_id_stored = await localStorage.getItem("customer_id");

    if (createNewUser) {
      post("/retailers", createUserdata)
        .then(async (response) => {
          if (response.retailer_id) {
            await localStorage.setItem("customer_id", response.retailer_id);
            structured["customer_id"] = response.retailer_id;
            await post("/survey/save", structured)
              .then(async (response) => {
                dispatch(setLoader(false));
                window.alert("Data Saved Successfully!");
              })
              .catch((error) => {
                dispatch(setLoader(false));
                window.alert("Something went wrong!");
              });
          }
        })
        .catch((error) => {
          navigate(`/?territory_id=${territoryID}&fo_id=${userID}`);
          dispatch(setLoader(false));
          window.alert("Something went wrong!");
        });
      setCreateNewUser(false);
      await localStorage.setItem("createnewuser", "false");
    } else {
      if (customer_id_stored || data.state.retailer.customer_id) {
        structured["customer_id"] = customer_id_stored
          ? customer_id_stored
          : data.state.retailer.customer_id;
      }
      post("/survey/save", structured)
        .then(async (response) => {
          dispatch(setLoader(false));
          await fetch(`${API_URL}/retailers/${structured["customer_id"]}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...createUserdata,
              customer_id: structured["customer_id"],
            }),
          }).catch((error) => {
            window.alert("User updation failed!");
          });
          window.alert("Data Saved Successfully!");
        })
        .catch((error) => {
          dispatch(setLoader(false));
          window.alert("Something went wrong!");
        });
    }
  };

  let territoryID = localStorage.getItem("territory_id");
  const getUserdata = (formData) => {
    return {
      customer_type: formData[Step1QuestionIdConstants.QUESTION_8],
      customer_name: formData[Step1QuestionIdConstants.QUESTION_1],
      village_id: formData["village_id"],
      sub_district: formData[Step1QuestionIdConstants.QUESTION_11],
      fot_1: formData[Step1QuestionIdConstants.QUESTION_16],
      shop_name: formData[Step1QuestionIdConstants.QUESTION_5],
      establishment_type: formData[Step1QuestionIdConstants.QUESTION_6],
      email_id: formData[Step1QuestionIdConstants.QUESTION_3],
      gstin: formData[Step1QuestionIdConstants.QUESTION_18],
      fot_1_id: formData["fot_id"],
      district: formData[Step1QuestionIdConstants.QUESTION_10],
      sub_district_id: formData["sub_district_id"],
      district_id: formData["district_id"],
      state: formData[Step1QuestionIdConstants.QUESTION_9],
      state_id: formData["state_id"],
      mobile_number: formData[Step1QuestionIdConstants.QUESTION_2],
      village: formData[Step1QuestionIdConstants.QUESTION_13],
      territory_id: territoryID,
      territory: formData[Step1QuestionIdConstants.QUESTION_15],
    };
  };

  const proceed = async () => {
    setReviewInfo(false);
    if (formData[Step1QuestionIdConstants.QUESTION_17].length !== 6) {
      window.alert("PIN number should be a 6 digit code!");
      return;
    }

    if (
      formData[Step1QuestionIdConstants.QUESTION_18] &&
      formData[Step1QuestionIdConstants.QUESTION_18].length !== 0 &&
      !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
        formData[Step1QuestionIdConstants.QUESTION_18]
      )
    ) {
      window.alert("Invalid GST number!");
      return;
    }

    dispatch(setLoader(true));
    let territoryID = await localStorage.getItem("territory_id");
    let userID = await localStorage.getItem("user_id");

    let PostBody = createPostBody(formData);

    const structured = {
      user_id: data.state.userID
        ? data.state.userID
        : data.state.parsedParameters
        ? data.state.parsedParameters.user_id
        : "",
      page_number: "page1",
      mobile_number: formData[Step1QuestionIdConstants.QUESTION_2],
      responses: PostBody,
      action: "save_next",
    };

    const createUserdata = getUserdata(formData);
    const customer_id_stored = await localStorage.getItem("customer_id");

    if (data.state.retailer || customer_id_stored) {
      structured["customer_id"] = customer_id_stored
        ? customer_id_stored
        : data.state.retailer.customer_id;
    }

    if (createNewUser) {
      post("/retailers", createUserdata)
        .then(async (response) => {
          if (response.retailer_id) {
            structured["customer_id"] = response.retailer_id;
            await localStorage.setItem("customer_id", response.retailer_id);
            await post("/survey/save", structured)
              .then(async (response) => {
                dispatch(setLoader(false));
                navigate("/step2", {
                  state: {
                    parsedParameters: data.state.parsedParameters,
                    customerID: structured["customer_id"],
                    user_id: userID,
                    territory_id: territoryID,
                    bayerSeller: formData[Step1QuestionIdConstants.QUESTION_7],
                  },
                });
              })
              .catch((error) => {
                console.log(error);
                dispatch(setLoader(false));
                window.alert("Something went wrong!1");
              });
          }
        })
        .catch((error) => {
          dispatch(setLoader(false));
          window.alert("Something went wrong!2");
        });
      setCreateNewUser(false);
      await localStorage.setItem("createnewuser", "false");
    } else {
      if (customer_id_stored || data.state.retailer.customer_id) {
        structured["customer_id"] = customer_id_stored
          ? customer_id_stored
          : data.state.retailer.customer_id;
      }
      post("/survey/save", structured)
        .then(async (response) => {
          dispatch(setLoader(false));
          await fetch(`${API_URL}/retailers/${structured["customer_id"]}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...createUserdata,
              customer_id: structured["customer_id"],
            }),
          }).catch((error) => {
            window.alert("User updation failed!");
          });
          navigate("/step2", {
            state: {
              parsedParameters: data.state.parsedParameters,
              customerID: structured["customer_id"],
              user_id: userID,
              territory_id: territoryID,
              bayerSeller: formData[Step1QuestionIdConstants.QUESTION_7],
            },
          });
        })
        .catch((error) => {
          dispatch(setLoader(false));
          window.alert("Something went wrong!");
          console.error("Error fetching data:", error);
        });
    }
  };
  const moveToStep2 = () => {
    if (validateFields()) {
      if (
        formData[Step1QuestionIdConstants.QUESTION_22] === "no" &&
        formData[Step1QuestionIdConstants.QUESTION_23] === "no" &&
        formData[Step1QuestionIdConstants.QUESTION_24] === "no"
      ) {
        setshowSorryPup(true);
      } else {
        setReviewInfo(true);
      }
    }
  };

  const fetchRetailerDetails = async () => {
    if (!data.state.createNewUser) {
      setFormData((prev) => ({
        ...prev,
        [Step1QuestionIdConstants.QUESTION_5]: data.state.retailer.shop_name,
        [Step1QuestionIdConstants.QUESTION_2]:
          data.state.retailer.mobile_number,
      }));
    }
  };

  useEffect(() => {
    fetchRetailerDetails();
  }, []);

  const renderField = (field) => {
    const {
      type,
      fieldName,
      identifier,
      placeholder,
      options,
      isHalf,
      isFirst,
      autopopulateColumn,
      isOptional,
    } = field;

    const isDisabled =
      (identifier === Step1QuestionIdConstants.QUESTION_10 &&
        !enabledFields.district) ||
      (identifier === Step1QuestionIdConstants.QUESTION_11 &&
        !enabledFields.subdistrict) ||
      (identifier === Step1QuestionIdConstants.QUESTION_13 &&
        !enabledFields.village) ||
      (identifier === Step1QuestionIdConstants.QUESTION_14 &&
        !enabledFields.urban) ||
      (identifier === Step1QuestionIdConstants.QUESTION_15 &&
        !enabledFields.territory) ||
      (identifier === Step1QuestionIdConstants.QUESTION_16 &&
        !enabledFields.fot);

    const hasError = fieldErrors[identifier];

    switch (type) {
      case "text":
      case "date":
        return (
          <TextDate
            identifier={identifier}
            fieldName={fieldName}
            type={type}
            placeholder={placeholder}
            formData={formData}
            handleChange={handleChange}
            hasError={hasError}
            isOptional={isOptional}
          />
        );
      case "singleSelect":
        return (
          <SingleSelect
            identifier={identifier}
            isHalf={isHalf}
            isFirst={isFirst}
            fieldName={fieldName}
            formData={formData}
            handleChange={handleChange}
            placeholder={placeholder}
            options={options}
            disabled={isDisabled}
            hasError={hasError}
            autopopulateColumn={autopopulateColumn}
            isOptional={isOptional}
          />
        );
      case "radio":
        return identifier === Step1QuestionIdConstants.QUESTION_12 ? (
          <RadioControl
            identifier={identifier}
            fieldName={fieldName}
            options={options}
            formData={formData}
            handleChange={handleChange}
            disabled={
              identifier === Step1QuestionIdConstants.QUESTION_12 &&
              !enabledFields.radioSelection
            }
            isOptional={isOptional}
          />
        ) : (
          <RadioControl
            identifier={identifier}
            fieldName={fieldName}
            options={options}
            formData={formData}
            handleChange={handleChange}
            hasError={hasError}
            isOptional={isOptional}
          />
        );

      default:
        return null;
    }
  };
  const showSaveProgress = () => {
    setSaveProgress(true);
  };
  return (
    <div>
      {showError && (
        <InfoPopUp
          close={() => {
            setShowError(false);
          }}
          heading={"Update Mandatory(*) fields"}
          text={"Please update all mandatory fields to proceed further"}
        />
      )}

      {Utils.loading && <LoaderFaded />}
      {saveProgress && (
        <SaveProgress
          close={() => {
            setSaveProgress(false);
          }}
          saveprogress={async () => {
            let territoryID = await localStorage.getItem("territory_id");
            let userID = await localStorage.getItem("user_id");
            saveProgresses();
            navigate(`/?territory_id=${territoryID}&fo_id=${userID}`);
          }}
        />
      )}
      {showSorryPup && (
        <HasLiscence
          SaveAndExit={() => {
            setshowSorryPup(false);

            let PostBody = createPostBody(formData);
            const structured = {
              user_id: data.state.userID
                ? data.state.userID
                : data.state.parsedParameters
                ? data.state.parsedParameters.user_id
                : "",
              page_number: "page1",
              responses: PostBody,
              action: "discontinue",
            };
            post("/survey/save", structured)
              .then((response) => {
                dispatch(setLoader(false));
                navigate(
                  `/?territory_id=${data.state.territoryID}&fo_id=${data.state.parsedParameters.user_id}`
                );
              })
              .catch((error) => {
                console.error("Error fetching data:", error);
              });

            navigate(
              `/?territory_id=${data.state.territoryID}&fo_id=${data.state.UserID}`
            );
          }}
          HaveLiscence={() => {
            setshowSorryPup(false);
          }}
        />
      )}
      {reviewInfo && <ProceedNotification close={close} proceed={proceed} />}
      <Header
        header={Constants.retailer_survey}
        subheader={Constants.step1}
        parsedParameters={data.state.parsedParameters}
        showSaveProgress={showSaveProgress}
      />
      <div style={Style.container}>
        {config.map((field) => renderField(field))}
      </div>
      <SaveAndCont onContinue={moveToStep2} saveProgress={saveProgresses} />
    </div>
  );
};
