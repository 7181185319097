import { useState } from "react";
import { colors, Font } from "../styles/standards";
import { Constants } from "../constants/textconstants";
import { ActionButton } from "./button";
import { useNavigate } from "react-router-dom";
import { post } from "../config/api";
import { InfoPopUp } from "./infoPopuop";
import { useSelector } from "react-redux";

export const Concent = ({
  phoneNumber,
  isNewUser,
  retailer,
  oldPhone,
  userID,
  territoryID,
  creatingUser,
}) => {
  const [selected, setSelected] = useState(true);
  const [loader, setLoader] = useState(false);
  const [showExisting, setShowExisting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // Declare state for error message
  const navigate = useNavigate();

  const retailers = useSelector((state) => state.retailers.retailers);

  // Helper function to fetch retailer data
  const fetchRetailerDetails = (phone) => {
    return retailers.find((element) => element.mobile_number === phone) || {};
  };

  const requestOTP = () => {
    // Check if survey is already completed
    if (
      retailers.some(
        (el) =>
          el.mobile_number === phoneNumber && el.survey_status === "closed"
      )
    ) {
      window.alert("A survey has already been completed on this number!");
      navigate(-1);
      return;
    }

    // Check if survey is in progress
    const isInProgress = retailers.some(
      (el) =>
        el.mobile_number === phoneNumber && el.survey_status === "in_progress"
    );

    if (!isInProgress) {
      setLoader(true);
      const data = {
        user_id: userID,
        mobile_number: phoneNumber,
        territory_id: territoryID,
      };

      if (oldPhone !== phoneNumber) {
        data["old_mobile_number"] = oldPhone;
      }

      post("/otp/send", data)
        .then(async (response) => {
          if (response.status === 409) {
            setLoader(false);
            const errorResponse = response;
            if (errorResponse?.status === 409) {
              const { territory, customer_name } = errorResponse;
              window.alert(
                `This phone number is already registered under (${territory}) territory.${customer_name ? ` Registered name: ${customer_name}.` : ""} Please use a different mobile number.`
              );
              navigate(-1);
            } else {
              window.alert("Something went wrong!");
            }
          }else{
            setLoader(false);
            await localStorage.setItem("createnewuser", "true");
            navigate("/verify_otp", {
              state: {
                phoneNumber,
                isNewUser,
                retailer,
                userID,
                territoryID,
                creatingUser,
              },
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      setShowExisting(true);
    }
  };

  return (
    <>
      {showExisting && (
        <InfoPopUp
          close={async () => {
            setShowExisting(false);

            // Fetch retailer details
            const retailer_data = fetchRetailerDetails(phoneNumber);

            // Update localStorage
            await localStorage.setItem("mobile_number", retailer_data.mobile_number);
            await localStorage.setItem("customer_id", retailer_data.customer_id);
            await localStorage.setItem("createnewuser", "false");

            // Navigate to step1
            navigate("/step1", {
              state: {
                isNewUser: false,
                retailer: retailer_data,
                userID,
                territoryID,
                createNewUser: false,
                mobile_number: phoneNumber,
              },
            });
          }}
          heading={"Survey In Progress!"}
          text={
            errorMessage ||
            "We found a survey in progress for this customer, proceed to survey."
          }
        />
      )}

      <ActionButton
        onPress={() => {
          requestOTP();
        }}
        text={Constants.request_otp}
        loader={loader}
        isEnabled={selected}
      />
    </>
  );
};

const Style = {
  Selected: {
    height: 25,
    width: 25,
    background: colors.Blue_Heilight,
    borderRadius: 3,
    color: colors.White,
    fontSize: 14,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${colors.Blue_Heilight}`,
  },
  NotSelected: {
    height: 25,
    width: 25,
    border: `1px solid ${colors.Blue_Dark}`,
    borderRadius: 3,
  },
  concent: {
    ...Font.RobotoMedium,
    color: colors.Blue_Dark,
    fontSize: 13,
    display: "block",
    marginLeft: 10,
  },
  container: {
    display: "flex",
    flexDirection: "row",
  },
};