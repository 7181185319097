import React, { useState } from "react";
import { colors, Font } from "../styles/standards";
import '../styles/select.css'

const TextInputWithUnit = ({ placeholder, currentvalue, onChange ,options, hasError}) => {
 
  const [value, setValue] = useState("");
  const [unit, setUnit] = useState(options[0].value.toLowerCase());

  const handleValueChange = async (e) => {


    const newValue = e.target.value;
    await setValue(newValue);
    
    if (onChange) {
   
     if(newValue==0){
      onChange(0, unit);
      return;
     }
      if(unit==='lakhs'){
         onChange(newValue * 100000, 'lakhs');
      }else if(unit==='crores'){
       
         onChange(newValue * 10000000, 'crores');
      } else if(unit==='mt'){
        
         onChange(newValue * 1000, 'mt');
      }else{
       
         onChange(newValue, 'kg');
      }
    }
  };

  const handleUnitChange = async (changed) => {
    const newUnit = changed;
    
    await setUnit(newUnit);
   
    if (onChange) {
      
      
      if(changed==='lakhs'){
        
         onChange(value * 100000, 'lakhs');
      }else if(changed==='crores'){
       
         onChange(value * 10000000,'crores');
      }
      else if(changed==='mt'){
       
         onChange(value * 1000, 'mt');
      }else{
         onChange(value, 'kg');
      }
    }
  };
  
  return (
    <div style={{...styles.container,  border: hasError? '1px solid red':'1px solid #efefef'}}>
      <input
        type="number"
        value={currentvalue }
        onChange={(e)=>{
         
          if(e.target.value!==''){
           
            handleValueChange(e);
          }else{
           
            onChange(0, unit)
          }
          
        }}
        placeholder={placeholder}
        style={styles.input}
      />
     <div className="select-wrapper">
     <select value={unit} onChange={(e)=>{
       
        let changed = e.target.value;
        handleUnitChange(changed)
      }} style={styles.dropdown}>
        {options.map((item, i)=>
          <option key={i} value={item.value.toLowerCase()}>{item.value}</option>
        )}
        
      </select>
     </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    width: "100%",
    overflow: "hidden",
   
  },
  input: {
    boxSizing: "border-box",
    display: "block",
    width: "100%",
    padding: 15,
    height: 50,
    border: `1px solid #a7a7a7`,
    borderRight: "0px solid #efefef",
    borderRadius: 4,
  },
  dropdown: {
    padding: 8,
    color:colors.Blue_Heilight,
    border: `1px solid #a7a7a7`,
    borderLeft: "0px solid #efefef",
    height: 50,
    ...Font.RobotoBold,
    fontSize: 14,
  },
};

export default TextInputWithUnit;
