import { Style } from "../styles/step1";
import Webcam from "react-webcam";

import "../components/ToggleSwitch.css";

import React, { useEffect, useState } from "react";
import { colors, Font } from "../styles/standards";
import { Step1QuestionIdConstants } from "../constants/textconstants";
import { Shop_Icon } from "../constants";

export const SingleSelect = ({
  identifier,
  isHalf,
  isFirst,
  fieldName,
  formData,
  handleChange,
  placeholder,
  options,
  disabled,
  hasError,
  autopopulateColumn,
  isOptional,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  if (
    identifier === Step1QuestionIdConstants.QUESTION_8 &&
    formData[Step1QuestionIdConstants.QUESTION_7] == "yes"
  ) {
    options = [
      { id: 1, value: "Distributor" },
      { id: 2, value: "Retailer" },
      { id: 3, value: "FPO" },
      { id: 4, value: "BLF" },
      { id: 5, value: "Cooperative Socities/Govt Bodies" },
      { id: 6, value: "E-Retailer (eg: Dehaat, Agrostar, etc.)" },
    ];
  }
  if (
    identifier === Step1QuestionIdConstants.QUESTION_8 &&
    formData[Step1QuestionIdConstants.QUESTION_7] == "no"
  ) {
    options = [
      { id: 2, value: "Retailer" },
      { id: 3, value: "FPO" },
      { id: 5, value: "Cooperative Socities/Govt Bodies" },
      { id: 6, value: "E-Retailer (eg: Dehaat, Agrostar, etc.)" },
    ];
  }

  const filteredOptions =
    searchTerm === ""
      ? options
      : options.filter((option) =>
          option.value.toLowerCase().includes(searchTerm.toLowerCase())
        );

  const handleSelect = (value) => {
    handleChange(identifier, value);
    setSearchTerm("");
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div
      key={identifier}
      style={
        isHalf
          ? {
              width: "47%",
              height: 93,
              float: "left",
              paddingRight: isFirst ? "3%" : "0%",
              paddingLeft: isFirst ? "0%" : "3%",
              opacity: disabled ? 0.3 : 1,
              pointerEvents: disabled ? "none" : "all",
            }
          : Style.fieldContainer
      }
    >
      <label style={Style.fieldName}>
        {fieldName}
        {!isOptional && (
          <mark style={{ color: "red", background: "#fff" }}>*</mark>
        )}
      </label>
      <div
        style={{
          ...Style.fieldElements,
          position: "relative",
          cursor: disabled || autopopulateColumn ? "not-allowed" : "pointer",
          pointerEvents: autopopulateColumn ? "none" : "all",
          background: autopopulateColumn ? colors.Grey : "#fff",
          border: hasError ? "1px solid red" : `1px solid ${colors.Grey_Dark}`,
        }}
        onClick={() => (disabled ? null : setIsOpen(!isOpen))}
      >
        {formData[identifier] || placeholder}
        <i
          className="fa fa-angle-down"
          style={{
            position: "absolute",
            right: 10,
            top: "50%",
            transform: "translateY(-50%)",
          }}
        ></i>
      </div>
      {hasError && (
        <span style={{ ...Font.RobotoMedium, color: "red", fontSize: 11 }}>
          This is a required field!
        </span>
      )}

      {isOpen && (
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9,
          }}
          className="test2"
        >
          <div
            style={{
              background: "rgba(0, 0, 0, 0.3)",
              width: "80%",
              zIndex: 1,
              padding: 10,
              borderRadius: 4,
              backgroundColor: "white",
              maxHeight: 350,
              overflow: "scroll",
            }}
            className="test1"
          >
            {/* {searchTerm !== "" && ( */}
            <input
              type="text"
              placeholder="Search..."
              style={{
                width: "100%",
                marginBottom: 10,
                height: 48,
                border: "none",
                outline: "none",
              }}
              onChange={handleSearch}
            />

            {filteredOptions.length > 0 ? (
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  height: filteredOptions.length > 10 ? 254 : "auto",
                  overflow: "scroll",
                  margin: 0,
                }}
              >
                {filteredOptions.map((option) => (
                  <li
                    key={option.id}
                    style={{ padding: "10px", cursor: "pointer" }}
                    onClick={() => handleSelect(option)}
                  >
                    {option.value}
                  </li>
                ))}
              </ul>
            ) : (
              <span>No results found.</span>
            )}
            <div
              style={{
                borderTop: "1px solid #efefef",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#efefef",
                padding: 10,
              }}
              onClick={handleCancel}
            >
              <span>Cancel</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const TextDate = ({
  identifier,
  fieldName,
  type,
  placeholder,
  formData,
  handleChange,
  hasError,
  isOptional,
}) => {
  return (
    <div key={identifier} style={Style.fieldContainer}>
      <label style={Style.fieldName}>
        {fieldName}
        {!isOptional && (
          <mark style={{ color: "red", background: "#fff" }}>*</mark>
        )}
      </label>
      <input
        type={type}
        placeholder={placeholder}
        value={
          identifier == Step1QuestionIdConstants.QUESTION_18
            ? formData[identifier] && formData[identifier].toUpperCase()
            : formData[identifier] || ""
        }
        onChange={(e) => {
          if (
            identifier == "pin" ||
            identifier === Step1QuestionIdConstants.QUESTION_4
          ) {
            handleChange(identifier, e.target.value.replace(/\D/g, ""));
          } else {
            handleChange(identifier, e.target.value);
          }
        }}
        style={
          hasError
            ? { ...Style.fieldElements, border: "1px solid red" }
            : identifier === Step1QuestionIdConstants.QUESTION_2
            ? {
                ...Style.fieldElements,
                pointerEvents: "none",
                backgroundColor: "#efefef",
              }
            : { ...Style.fieldElements }
        }
      />
      {hasError && (
        <span style={{ ...Font.RobotoMedium, color: "red", fontSize: 11 }}>
          This is a required field!
        </span>
      )}
      {identifier === Step1QuestionIdConstants.QUESTION_3 &&
      formData[identifier] &&
      formData[identifier] !== "" ? (
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData[identifier]) ? (
          ""
        ) : (
          <span style={{ ...Font.RobotoMedium, color: "red", fontSize: 11 }}>
            Please enter a valid email!
          </span>
        )
      ) : (
        ""
      )}
      {identifier === Step1QuestionIdConstants.QUESTION_18 &&
      formData[identifier] &&
      formData[identifier] !== "" ? (
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
          formData[identifier]
        ) ? (
          ""
        ) : (
          <span style={{ ...Font.RobotoMedium, color: "red", fontSize: 11 }}>
            Please enter a valid GSTIN no.!
          </span>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export const RadioControl = ({
  identifier,
  fieldName,
  options,
  formData,
  handleChange,
  disabled,
  hasError,
  isOptional,
}) => {
  // useEffect(()=>{
  //   handleChange(identifier,"Village" )
  // },[])

  if (identifier === Step1QuestionIdConstants.QUESTION_12) {
    return (
      <div
        key={identifier}
        className="sliding-toggle-container"
        style={{
          ...Style.fieldContainer,
          marginTop:
            identifier === Step1QuestionIdConstants.QUESTION_12 ? 20 : 0,
          opacity: disabled ? 0.3 : 1,
          pointerEvents: disabled ? "none" : "all",
          float: "left",
          width: "100%",
        }}
      >
        <label
          className="field-label"
          style={{ ...Style.fieldName, marginBottom: 15 }}
        >
          {fieldName}
        </label>
        <div style={Style.toggleContainer}>
          <span style={Style.toggleText}>{options[0].value}</span>
          <div className="toggle-slider">
            {/* Slider background */}
            <div
              className="slider"
              style={{
                transform:
                  formData[identifier] === options[1]?.value
                    ? "translateX(100%)"
                    : "translateX(0)",
              }}
            ></div>

            {/* Radio buttons */}
            {options.map((option, i) => (
              <label
                key={option.id}
                className="toggle-label"
                htmlFor={`${identifier}-${i}`}
                style={{ cursor: "pointer" }}
              >
                <input
                  type="radio"
                  name={identifier}
                  id={`${identifier}-${i}`}
                  className="toggle-input"
                  value={option.value}
                  checked={formData[identifier] === option.value}
                  onChange={(e) => handleChange(identifier, e.target.value)} // Ensure onChange works
                />
              </label>
            ))}
          </div>
          <span style={Style.toggleText}> {options[1].value}</span>
        </div>
      </div>
    );
  } else {
    return (
      <div
        key={identifier}
        style={
          identifier === Step1QuestionIdConstants.QUESTION_12
            ? {
                ...Style.fieldContainer,
                marginTop: 20,
                opacity: disabled ? 0.3 : 1,
                pointerEvents: disabled ? "none" : "all",
              }
            : Style.fieldContainer
        }
      >
        <label style={Style.fieldName}>
          {fieldName}
          {!isOptional && (
            <mark style={{ color: "red", background: "#fff" }}>*</mark>
          )}
        </label>
        {hasError && (
          <span style={{ ...Font.RobotoMedium, color: "red", fontSize: 11 }}>
            This is a required field!
          </span>
        )}

        <div style={{ display: "flex" }}>
          {options.map((option) => (
            <label
              key={option.id}
              style={{
                display: "flex",
                margin: "5px 10px",
                flexDirection: "row",
              }}
            >
              <input
                type="radio"
                name={identifier}
                style={{ height: 23, width: 23 }}
                value={option.value}
                checked={formData[identifier] === option.value}
                onChange={(e) => handleChange(identifier, e.target.value)}
              />
              <span style={{ display: "block", margin: "3px 10px" }}>
                {" "}
                {option.value}
              </span>
            </label>
          ))}
        </div>
      </div>
    );
  }
};

export const PhotoController = ({
  identifier,
  fieldName,
  image,
  clickToCapture,
  webcamRef,
  capturePhoto,
  setClickToCapture,
  setImage,
  formData,
  isOptional,
}) => {
  const videoConstraints = {
    facingMode: "environment",
  };

  return (
    <div key={identifier} style={Style.fieldContainer}>
      <label style={Style.fieldName}>
        {fieldName}
        {isOptional && (
          <mark style={{ color: "red", background: "#fff" }}>*</mark>
        )}
      </label>
      {!image ? (
        <>
          {clickToCapture ? (
            <>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                style={{ width: "100%", maxWidth: "500px" }}
                videoConstraints={videoConstraints}
              />
              <button
                style={{
                  background: colors.Blue_Heilight,
                  padding: " 13px 20px",
                  borderRadius: 9,
                  marginTop: 10,
                  color: colors.White,
                  border: "1px solid #fff",
                  width:148,
                  display:'flex',
                  justifyContent:'space-around',
                  alignItems:'center',
                  ...Font.RobotoBold,
                   margin: '20px auto',
                  fontSize:18
                }}
                onClick={capturePhoto}
              >
                 <i className="fa fa-camera" style={{fontSize:20}}></i>
                Capture
              </button>
            </>
          ) : (
            <div
              style={Style.camera}
              onClick={() => {
                setClickToCapture(true);
              }}
            >
              {formData["shopPhoto"] && (
                <img
                  src={formData["shopPhoto"]}
                  style={{
                    height: 151,
                    width: "100%",
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    objectFit: "cover",
                  }}
                />
              )}
              <img src={Shop_Icon} style={{ height: 100 }}></img>
              <span style={{ zIndex: 99 }}>Click here to take shop photo!</span>
            </div>
          )}
        </>
      ) : (
        <>
          <img
            src={image}
            alt="Captured"
            style={{ width: "100%", maxWidth: "500px" }}
          />
          <button
            style={{
              background: colors.Blue_Heilight,
              padding: " 13px 20px",
              borderRadius: 9,
              marginTop: 10,
              color: colors.White,
              border: "1px solid #fff",
              width:148,
              display:'flex',
              justifyContent:'space-around',
              alignItems:'center',
              ...Font.RobotoBold,
              margin: '20px auto',
              fontSize:18
            }}
            onClick={() => setImage(null)}
          >
            <i className="fa fa-camera" style={{fontSize:20}}></i>
            Retake
          </button>
        </>
      )}
    </div>
  );
};

export const Cordinates = ({
  identifier,
  fieldName,
  getLocation,
  formData,
  locationError,
}) => {
  useEffect(() => {
    getLocation(identifier);
  }, []);
  return (
    <div key={identifier} style={Style.fieldContainer}>
      <label style={Style.fieldName}>{fieldName}</label>
      {formData[identifier] && (
        <div>
          <p>Lat: {formData[identifier].latitude}</p>
          <p>Long: {formData[identifier].longitude}</p>
        </div>
      )}
      {locationError && (
        <p
          onClick={() => {
            getLocation(identifier);
          }}
          style={{ color: "red" }}
        >
          {locationError}{" "}
          <mark
            style={{
              padding: 10,
              display: "block",
              width: 80,
              background: colors.Blue_Heilight,
              marginTop: 10,
              color: colors.White,
              borderRadius: 3,
            }}
          >
            Click here!
          </mark>
        </p>
      )}
    </div>
  );
};

export const formatNumberToUnits = (number) => {
  if (number === null || number === undefined) return "0";

  const absNumber = Math.abs(number);
  if (absNumber >= 10000000) {
    return `${number / 10000000} ${
      number / 10000000 === 1 ? "Crore" : "Crores"
    }`; // Crore(s)
  } else if (absNumber >= 100000) {
    return `${number / 100000} ${number / 100000 === 1 ? "Lakh" : "Lakhs"}`; // Lakh(s)
  } else {
    return number.toLocaleString(); // Regular number with commas
  }
};
