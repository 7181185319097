import { API_URL } from "./sys";

export const FetchRetailers = `${API_URL}/retailers`;
export const SendOTP = `${API_URL}/otp/send`;
export const VerifyOTPs = `${API_URL}/otp/verify`;

export const get = async (endpoint, params) => {
  const url = new URL(`${API_URL}${endpoint}`);
  if (params) {
    Object.entries(params).forEach(([key, value]) =>
      url.searchParams.append(key, value)
    );
  }

  const response = await fetch(url);

  if (response.ok) {
    if (response.status === 204) {
      console.log("No content returned from the server.");
      return null;
    } else if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      const errorText = await response.text();
      throw new Error(`HTTP error ${response.status}: ${errorText}`);
    }
  }
};

export const post = async (endpoint, data) => {
  const response = await fetch(`${API_URL}${endpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (response.ok) {
    console.log("REQUEST FINE");
  } else {
    const responseData = await response.json();
    if (
      responseData.message === "Failed to create retailer" &&
      response.status === 500
    ) {
      window.alert(
        "A survey is already under progress/completed for this user, please contact your Admin for more info"
      );
      return;
    } else if (response.status === 409) {
      return { ...responseData, status: response.status };
    } else {
      window.alert("Something went Wrong!");
      return;
    }
  }

  const responseData = await response.json();
  return responseData;
};
