import { useEffect, useRef, useState } from "react";

import { DropSelector } from "../components/dropDown";
import { Header } from "../components/header";
import { Wrapper } from "../components/wrapper";
import { Constants, FOT, subDist } from "../constants/textconstants";
import { useDispatch, useSelector } from "react-redux";
import { SearchableModal } from "./searchableModal";
import { fetchAllRetailers } from "../store/slices/retailersList";
import { Styles } from "../styles/headerwithSearch";
import { WrapperRow } from "../components/WrapperRow";
import { RetailersList } from "../components/retailersList";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Loader } from "../components/loader";
import { NotAuthorized } from "../components/notAuthorized";
import { NoStores } from "../components/noSTorsfound";
import { useQueryParams } from "../hooks/useQueryParams";

export const Home = () => {
  const queryParams = useQueryParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSelector, setDataDelector] = useState({
    fot: false,
    subdist: false,
  });
  const { selectedFOT, selectedSubDist, selectedFOTvalue } = useSelector(
    (state) => state.subDist
  );
  const [searchParams] = useSearchParams();
  const { subdistrict, fot, loading, retailers } = useSelector(
    (state) => state.retailers
  );
  const [filteredSubs, setFilteredSubs] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [parsedParameters, setParsedParameters] = useState({
    territory_id: queryParams.territory_id,
    user_id: queryParams.fo_id,
    fo_phone_number: queryParams.fo_phone_number,
  });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("territory_id", queryParams.territory_id);
    localStorage.setItem("user_id", queryParams.fo_id);
    const handlePopState = () => {
      // Redirect the user to the current page
      navigate(location.pathname, { replace: true });
    };

    // Add a popstate event listener to handle the back button
    window.addEventListener("popstate", handlePopState);

    return () => {
      // Clean up the event listener
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate, location.pathname]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedFOTvalue) {
      setFilteredSubs(
        subdistrict.filter((item) => item.relatedFT === selectedFOTvalue)
      );
    }
  }, [selectedFOTvalue]);

  const handleClick = async (selected) => {
    if (selected === Constants.fot) {
      setDataDelector({ fot: true, subdist: false });
    } else {
      setDataDelector({ fot: false, subdist: true });
    }
    setIsModalOpen(true);
  };

  const clearCustomerId = async () => {
    await localStorage.removeItem("customer_id");
    await localStorage.removeItem("mobile_number");
  };
  useEffect(() => {
    clearCustomerId();
  }, []);

  const hasFetched = useRef(false); // Track if data has been fetched

  useEffect(() => {
    if (queryParams.territory_id && queryParams.fo_id) {
      setIsAuthorized(true);
      if (parsedParameters["territory_id"] && parsedParameters["user_id"]) {
        setParsedParameters(parsedParameters);
      }
      if (!hasFetched.current) {
        //check if the data is already fetched
        dispatch(fetchAllRetailers(queryParams));
        hasFetched.current = true; //set true after fetching the data
      }
    } else {
      setIsAuthorized(false);
      hasFetched.current = false; //reset the hasFetched value to false if the params are changed
    }
  }, [queryParams, dispatch]);

  return (
    <>
      {!isAuthorized ? (
        <NotAuthorized />
      ) : (
        <div>
          <div style={Styles.fixedContainer}>
            <Header
              isNewSurvay={true}
              header={Constants.retailer_survey}
              subheader={""}
              userID={parsedParameters["user_id"]}
              territoryID={parsedParameters["territory_id"]}
            />
            <WrapperRow>
              <Wrapper
                isDisabled={false}
                fieldTag={Constants.fot}
                isHalf={true}
              >
                <DropSelector
                  SelectedData={selectedFOT ?? null}
                  placeHolder={FOT.placeholder}
                  onPress={() => {
                    handleClick(Constants.fot);
                  }}
                />
              </Wrapper>
              <Wrapper
                isDisabled={selectedFOT === null}
                fieldTag={Constants.sub_dist}
                isHalf={true}
              >
                <DropSelector
                  SelectedData={selectedSubDist ?? null}
                  placeHolder={subDist.placeholder}
                  onPress={() => {
                    handleClick(Constants.sub_dist);
                  }}
                />
              </Wrapper>
            </WrapperRow>
          </div>

          {isModalOpen && (
            <SearchableModal
              data={dataSelector.fot ? fot : filteredSubs}
              loading={loading}
              onClose={() => setIsModalOpen(false)}
              texts={dataSelector.fot ? FOT : subDist}
            />
          )}
          {loading ? (
            <Loader />
          ) : (
            <RetailersList parsedParameters={parsedParameters} />
          )}
        </div>
      )}
    </>
  );
};
