import { get } from "../config/api";

export const filterSubDistrictsandFOT = (inputArray) => {
  const result = { subdist: [], fot: [] };
  const subdistSet = new Set();
  // To track unique subdistrict entries
  const fotSet = new Set(); // To track unique FOT entries

  inputArray.forEach((item) => {
    if (item.sub_district && item.sub_district_id) {
      const subdistKey = `${item.sub_district}-${item.sub_district_id.trim()}`;
      if (!subdistSet.has(subdistKey)) {
        subdistSet.add(subdistKey);
        result.subdist.push({
          subdist: item.sub_district,
          subdistID: item.sub_district_id.trim(),
          relatedFT: item.fot_id,
        });
      }
    }
    if (item.fot_id && item.fot) {
      const fotKey = `${item.fot_id}-${item.fot}`;
      if (!fotSet.has(fotKey)) {
        fotSet.add(fotKey);
        result.fot.push({ fot: item.fot, fotid: item.fot_id });
      }
    }
  });

  return result;
};

export const getStates = async () => {
  let StatesData = [];
  StatesData = [
    {
      state_code: "10",
      state_name: "Bihar",
    },
    {
      state_code: "3",
      state_name: "Punjab",
    },
    {
      state_code: "13",
      state_name: "Nagaland",
    },
    {
      state_code: "18",
      state_name: "Assam",
    },
    {
      state_code: "90",
      state_name: "Telangana",
    },
    {
      state_code: "23",
      state_name: "Madhya Pradesh",
    },
    {
      state_code: "8",
      state_name: "Rajasthan",
    },
    {
      state_code: "9",
      state_name: "Uttar Pradesh",
    },
    {
      state_code: "27",
      state_name: "Maharashtra",
    },
    {
      state_code: "91",
      state_name: "Jammu And Kashmir",
    },
    {
      state_code: "22",
      state_name: "Chhattisgarh",
    },
    {
      state_code: "33",
      state_name: "Tamil Nadu",
    },
    {
      state_code: "29",
      state_name: "Karnataka",
    },
    {
      state_code: "21",
      state_name: "Odisha",
    },
    {
      state_code: "2",
      state_name: "Himachal Pradesh",
    },
    {
      state_code: "5",
      state_name: "Uttarakhand",
    },
    {
      state_code: "14",
      state_name: "Manipur",
    },
    {
      state_code: "6",
      state_name: "Haryana",
    },
    {
      state_code: "19",
      state_name: "West Bengal",
    },
    {
      state_code: "28",
      state_name: "Andhra Pradesh",
    },
    {
      state_code: "24",
      state_name: "Gujarat",
    },
    {
      state_code: "20",
      state_name: "Jharkhand",
    },
    {
      state_code: "15",
      state_name: "Mizoram",
    },
    {
      state_code: "12",
      state_name: "Arunachal Pradesh",
    },
    {
      state_code: "32",
      state_name: "Kerala",
    },
    {
      state_code: "7",
      state_name: "Delhi",
    },
    {
      state_code: "17",
      state_name: "Meghalaya",
    },
    {
      state_code: "16",
      state_name: "Tripura",
    },
    {
      state_code: "34",
      state_name: "Puducherry",
    },
    {
      state_code: "11",
      state_name: "Sikkim",
    },
    {
      state_code: "4",
      state_name: "Chandigarh",
    },
    {
      state_code: "30",
      state_name: "Goa",
    },
  ];
  const newData = StatesData.map((item, i) => ({
    id: item["state_code"],
    value: item["state_name"],
  }));
  return newData.sort((a, b) => {
    return a.value.localeCompare(b.value);
  });
};

export const getDistrict = async (state) => {
  // make API call here
  let DistrictsData = [];
  await get(`/locations/districts?state_code=${state}`).then(async (data) => {
    DistrictsData = data;
  });
  const newData = DistrictsData.map((item, i) => ({
    id: item["district_code"],
    value: item["district_name"],
  }));
  return newData.sort((a, b) => {
    return a.value.localeCompare(b.value);
  });
};

export const getSubDistrict = async (district) => {
  // make API call here
  let subDistrictsData = [];
  await get(`/locations/subdistricts?district_code=${district}`).then(
    async (data) => {
      subDistrictsData = data;
    }
  );
  const newData = subDistrictsData.map((item, i) => ({
    id: item["sub_district_code"],
    value: item["sub_district_name"],
  }));
  return newData.sort((a, b) => {
    return a.value.localeCompare(b.value);
  });
};

export const getVillages = async (subdistrict) => {
  // make API call here
  let villegesData = [];
  await get(
    `/locations/villages?sub_district_code=${
      subdistrict.length === 9 ? "0" + subdistrict : subdistrict
    }`
  ).then(async (data) => {
    villegesData = data;
  });
  const newData = villegesData.map((item, i) => ({
    id: item["village_code"],
    value: item["village_name"],
    ...item,
  }));
  return newData.sort((a, b) => {
    return a.value.localeCompare(b.value);
  });
};

export const getUrbanBodies = async (subdistrict) => {
  // make API call here
  let UrbanBodiesData = [];
  await get(
    `/locations/urban-bodies?sub_district_code=${
      subdistrict.length === 9 ? "0" + subdistrict : subdistrict
    }`
  ).then(async (data) => {
    UrbanBodiesData = data;
  });
  const newData = UrbanBodiesData.map((item, i) => ({
    id: item["urban_body_code"],
    value: item["urban_body_name"],
    ...item,
  }));
  return newData.sort((a, b) => {
    return a.value.localeCompare(b.value);
  });
};

export const getTerritoryAndFOT = async (data) => {
  // make API call here

  return {
    territory: data.territory_name,
    fot: data.fot_name,
    ...data,
  };
};

export const getTerritoryAndFOTForUrbanBody = async (territory_id) => {
  // make API call here
  let FOTdetail = {};
  await get(`/locations/fot-mappings?territory_code=${territory_id}`).then(async (data) => {
    FOTdetail = data;
  });
  const newData = await FOTdetail && FOTdetail.fots.map((item, i) => ({
    id: item["fot_code"],
    value: item["fot_name"],
  }));
  return { 'territory_name':FOTdetail.territory_name, fots:newData};
 
};

function groupResponsesByQuestionId(data) {
  const groupedResponses = {};

  data.responses.forEach((response) => {
    const { question_id, response: responseObj } = response;

    if (!groupedResponses[question_id]) {
      groupedResponses[question_id] = {
        question_id,
        response: {},
      };
    }

    Object.assign(groupedResponses[question_id].response, responseObj);
  });

  return Object.values(groupedResponses);
}

export const restructureAnswers = (config, answers) => {
  const outputObject = {};
  answers.forEach((item) => {
    outputObject[item.identifier] = item.answer;
  });

  const restructuredData = {
    responses: [],
  };

  const configMap = {};
  config.forEach((section, sectionIndex) => {
    if (section.sectionquestions) {
      section.sectionquestions.forEach((subSection) => {
        subSection.questions.forEach((question) => {
          configMap[question.identifier] = {
            sectionIndex,
            ...question,
          };
        });
      });
    } else {
      section.questions.forEach((question) => {
        configMap[question.identifier] = {
          sectionIndex,
          ...question,
        };
      });
    }
  });

  const groupedResponses = {};
  answers.forEach((answer) => {
    const questionConfig = configMap[answer.identifier];
    const { sectionIndex } = questionConfig;

    if (!groupedResponses[sectionIndex]) {
      groupedResponses[sectionIndex] = [];
    }

    groupedResponses[sectionIndex].push({
      question_id: sectionIndex + 1 + "",
      response: {
        [questionConfig.identifier]: answer.answer,
      },
    });
  });

  // Flatten the grouped responses into a single array
  const flattenedResponses = [];
  for (const sectionIndex in groupedResponses) {
    flattenedResponses.push(...groupedResponses[sectionIndex]);
  }

  restructuredData.responses = flattenedResponses;
  const groupedData = groupResponsesByQuestionId(restructuredData);
  return groupedData;
};
