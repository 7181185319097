export const cornKeywords = [
  "corn_company_name1",
  "corn_company_name2",
  "corn_company_name3",
  "corn_company_user_type1",
  "corn_company_user_type2",
  "corn_company_user_type3",
  "corn_company_total_sale1",
  "corn_company_total_sale2",
  "corn_company_total_sale3",
  "corn_company_total_volume1",
  "corn_company_total_volume2",
  "corn_company_total_volume3",
  "corn_company_wholesale1",
  "corn_company_wholesale2",
  "corn_company_wholesale3",
  "corn_company_averagendp1",
  "corn_company_averagendp2",
  "corn_company_averagendp3",
  "corn_bayer_type",
  "corn_bayer_total_sale_2024",
  "corn_bayer_total_sale_mt",
  "corn_bayer_wholesale",
  "corn_bayer_averagendp",
  "corn_other_companies",
  "corn_totalsale",
  "corn_totalvolume",
];

export const updateCornData = (
  updatedFormData,
  updatedDataToPrepopulate,
  identifier
) => {
  let updatedFormData1 = updatedFormData;
  let updatedDataToPrepopulate1 = updatedDataToPrepopulate;
  let identifier1 = identifier;

  let sum = 0;
  if (
    (identifier1 === "corn_company_total_sale1" ||
      identifier1 === "corn_company_total_volume1") &&
    updatedFormData1["corn_company_total_volume1"] &&
    updatedFormData1["corn_company_total_sale1"]
  ) {
    sum =
      updatedFormData1["corn_company_total_sale1"] /
      updatedFormData1["corn_company_total_volume1"];
    updatedFormData1["corn_company_averagendp1"] = sum;
    updatedDataToPrepopulate1["corn_company_averagendp1"] = sum;
  } else if (
    (identifier1 === "corn_company_total_sale2" ||
      identifier1 === "corn_company_total_volume2") &&
    updatedFormData1["corn_company_total_volume2"] &&
    updatedFormData1["corn_company_total_sale2"]
  ) {
    sum =
      updatedFormData1["corn_company_total_sale2"] /
      updatedFormData1["corn_company_total_volume2"];
    updatedFormData1["corn_company_averagendp2"] = sum;
    updatedDataToPrepopulate1["corn_company_averagendp2"] = sum;
  } else if (
    (identifier1 === "corn_company_total_sale3" ||
      identifier1 === "corn_company_total_volume3") &&
    updatedFormData1["corn_company_total_volume3"] &&
    updatedFormData1["corn_company_total_sale3"]
  ) {
    sum =
      updatedFormData1["corn_company_total_sale3"] /
      updatedFormData1["corn_company_total_volume3"];
    updatedFormData1["corn_company_averagendp3"] = sum;
    updatedDataToPrepopulate1["corn_company_averagendp3"] = sum;
  } else if (
    (identifier1 === "corn_bayer_total_sale_2024" ||
      identifier1 === "corn_bayer_total_sale_mt") &&
    updatedFormData1["corn_bayer_total_sale_mt"] &&
    updatedFormData1["corn_bayer_total_sale_2024"]
  ) {
    sum =
      updatedFormData1["corn_bayer_total_sale_2024"] /
      updatedFormData1["corn_bayer_total_sale_mt"];
    updatedFormData1["corn_bayer_averagendp"] = sum;
    updatedDataToPrepopulate1["corn_bayer_averagendp"] = sum;
  }
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  let OtherSum = (parseInt(updatedFormData1["SEE_cornhybrid_retail"])
    ? parseInt(updatedFormData1["SEE_cornhybrid_retail"])
    : 0) -
     ( (parseInt(updatedFormData1["corn_company_total_sale1"])
        ? parseInt(updatedFormData1["corn_company_total_sale1"])
        : 0) 
        +
        (parseInt(updatedFormData1["corn_company_total_sale2"])
          ? parseInt(updatedFormData1["corn_company_total_sale2"])
          : 0) +
        (parseInt(updatedFormData1["corn_company_total_sale3"])
          ? parseInt(updatedFormData1["corn_company_total_sale3"])
          : 0) +
        (parseInt(updatedFormData1["corn_bayer_total_sale_2024"])
          ? parseInt(updatedFormData1["corn_bayer_total_sale_2024"])
          : 0));


  const otherAccumalation =  ( (parseInt(updatedFormData1["corn_company_total_sale1"])
  ? parseInt(updatedFormData1["corn_company_total_sale1"])
  : 0) 
  +
  (parseInt(updatedFormData1["corn_company_total_sale2"])
    ? parseInt(updatedFormData1["corn_company_total_sale2"])
    : 0) +
  (parseInt(updatedFormData1["corn_company_total_sale3"])
    ? parseInt(updatedFormData1["corn_company_total_sale3"])
    : 0) +
  (parseInt(updatedFormData1["corn_bayer_total_sale_2024"])
    ? parseInt(updatedFormData1["corn_bayer_total_sale_2024"])
    : 0))
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>


  updatedFormData1["corn_totalvolume"] =
    parseInt(
      updatedFormData1["corn_company_total_volume1"]
        ? updatedFormData1["corn_company_total_volume1"]
        : 0
    ) +
    parseInt(
      updatedFormData1["corn_company_total_volume2"]
        ? updatedFormData1["corn_company_total_volume2"]
        : 0
    ) +
    parseInt(updatedFormData1["corn_company_total_volume3"]
      ? updatedFormData1["corn_company_total_volume3"]
      : 0 
    )+ 
      
      parseInt(updatedFormData1["corn_bayer_total_sale_mt"]
      ? updatedFormData1["corn_bayer_total_sale_mt"]
      : 0
    );


  updatedFormData1["corn_other_companies"] =
    parseInt(updatedFormData1["SEE_cornhybrid_retail"]) - otherAccumalation;

  updatedDataToPrepopulate1["corn_other_companies"] =
    parseInt(updatedFormData1["SEE_cornhybrid_retail"]) - otherAccumalation;

  updatedFormData1["corn_totalsale"] = parseInt(updatedFormData1["SEE_cornhybrid_retail"]);
  updatedDataToPrepopulate1["corn_totalsale"] = parseInt(updatedFormData1["SEE_cornhybrid_retail"]);

  updatedDataToPrepopulate1["corn_totalvolume"] =
    updatedFormData1["corn_totalvolume"];

  return {
    updatedDataToPrepopulate1: updatedDataToPrepopulate1,
    updatedFormData1: updatedFormData1,
  };
};
